.SuccessDialogTitle {
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 0.07px;
    color: #2d3b59;
    text-align: center;
    font-family: Georgia;
}

.welcomeEmailFailureDialogTitle {
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 0.07px;
    color: #2e334e;
    text-align: center;
    font-family: Georgia;
}

.successDialogButton {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.WarningDialogText11 {
    font-family: georgia;
    font-size: 21px;
    font-weight: bold;
}

.cslFailureCloseButton {
    width: 98px;
    height: 28px;
    border-radius: 3px;
    border: solid 1.5px #2d3b59;
    font-family: museo-sans;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    background-color: #ffffff;
    color: #2d3b59;
    font-size: 10px;
    margin: 0 0px 0 0px;
}

.cslFailureDialogText {
    font-size: 14px;
    line-height: 19px;
    font-weight: 500;
    font-family: museo-sans;
    padding: 0 6px;
    color: #4b4b4b;
    text-align: center;
}