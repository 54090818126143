.WarningDialogContainer {
    border-radius: 4px;
    background-color: #f7f7f7;
    padding: 10px;
    width: 408px;
}

.warningDialogIcon {
    margin-right: 10px;
}

.WarningDialogText1 {
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 19px;
    letter-spacing: normal;
    font-family: museo-sans;
    text-align: center;
    color: #757575;
}

.dialogList {
    text-align: left;
}

.WarningDialogInnerContainer {
    padding: 39px 45px 0;
    border-radius: 4px;
    background-color: #ffffff;
}

.WarningDialogTextContainer {
    margin: 24px 0 30px;
}

.warningDialogButton{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 38px;
}

.warningDialogCancel {
    width: 88px;
    padding: 10px 23px;
    border-radius: 3.5px;
    border: solid 2.5px #2d3b59;
    font-family: museo-sans;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 15.6px;
    letter-spacing: normal;
    font-size: 13px;
    color: #2d3b59;
    background-color: #ffffff;
    margin: 0 5px 0 5px;
}

.warningDialogConfirm {
    width: 88px;
    padding: 10px 23px;
    border-radius: 3.5px;
    border: solid 2.5px #2d3b59;
    font-family: museo-sans;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 15.6px;
    letter-spacing: normal;
    color: white;
    background-color: #2d3b59;
    font-size: 13px;
    margin: 0 5px 0 5px;
}

.warningDialogSkip {
    width: 88px;
    border-radius: 3.5px;
    background-color: #f7f7f7;
    font-family: museo-sans;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2d3b59;
    font-size: 13px;
    margin: 0 5px 0 5px;
}